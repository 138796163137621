//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions, mapMutations } from 'vuex';
import PincodeInput from 'vue-pincode-input';
import { i18n } from '@/i18n';
import Message from '@/shared/message/message';

export default {
  name: 'app-phone-unverified-page',
  props: ['type'],

  components:{
    PincodeInput
  },

  data() {
    return {
      code: '',
      phoneNum: localStorage.getItem('phoneNum')
    };
  },
  mounted() {
    this.AUTH_END()
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),
    isSignUp() {
      return this.type == 'signup'
    }
  },
  
  methods: {
    ...mapMutations({  
      AUTH_END:'auth/AUTH_END'
    }),
    ...mapActions({
      signPhoneNumberCredential: 'auth/signPhoneNumberCredential',
      signUpPhoneNumberCredential: 'auth/signUpPhoneNumberCredential',
     
    }),
    i18n(code) {
      return i18n(code);
    },
    doPrev() {
      this.$emit('doPrev');
    },    
    doNext() {
      this.$emit('doNext');
    },
    async doSubmit() {
      if (this.isSignUp) {
        // debugger
        const response = await this.signUpPhoneNumberCredential(this.code)      
        if(response== 'error'){
        
        console.log('>>>>>'+(this.code));
        
        Message.error(i18n('Invalid Verification code'));
        this.code = ''
        // debugger

        }else if(response == 'verified'){
           console.log('>>>>>'+(this.code));
          this.doNext();
        
        }



        
      } else {
        await this.signPhoneNumberCredential(this.code)
      }
    },
  },
  created(){
    console.log('phone unverified')
  }
};
